"use client";

import React from "react";
import { ScrollPagination } from "./Pagination";
import Divider from "./Divider";
import Image from "next/image";
import Link from "next/link";
import { AvatarImage } from "@/public/images";
import { ArrowRightSvg } from "@/public/svg";
import { twMerge } from "tailwind-merge";

export default function Testimoni({ datas }) {
  return (
    <ScrollPagination
      datas={datas}
      className={"flex gap-4 px-gon"}
      renderItem={({ item, index }) =>
        datas?.length > 4 && index == datas?.length - 1 ? (
          <Link
            aria-label={`testimoni`}
            as={"/testimoni"}
            shallow
            href={"/testimoni"}
          >
            <div className="flex flex-row gap-2 xs:gap-4 w-auto h-[200px] max-w-sm min-w-[265px] lg:min-w-[365px] p-4 my-4 xs:p-6 xs:my-6 rounded justify-center items-center border border-greyscaleGON-100 hover:border-greyscaleGON-300 cursor-pointer">
              <span className="p1-semiBold text-greyscaleGON-700 capitalize text-center">
                lihat lainnya
              </span>
              <ArrowRightSvg className="h-6 w-6 text-primaryGON-400" />
            </div>
          </Link>
        ) : !item?.message ? null : (
          <div className="flex cursor-default flex-col gap-2 xs:gap-4 w-auto h-[200px] max-w-sm min-w-[265px] lg:min-w-[365px] p-4 my-4 xs:p-6 xs:my-6 rounded bg-shadesGON-50 shadow-smallGON hover:shadow-mediumGON hover:shadow-primaryGON-50">
            <div className="h-[60%]  w-full overflow-hidden overflow-y-scroll">
              <span className="p2-regular xs:p1-regular text-greyscaleGON-700 ">
                “{item?.message}”
              </span>
            </div>
            <Divider />
            <div className="h-[40%]  flex gap-4 items-center">
              <div className="w-10 h-10">
                <Image
                  unoptimized
                  alt={`as_${index}`}
                  src={item?.link_icon ?? AvatarImage}
                  width={140}
                  height={140}
                  className="w-auto h-auto bg-cover bg-center rounded-full"
                  placeholder="blur"
                  blurDataURL={AvatarImage?.blurDataURL}
                />
              </div>
              <div className="flex flex-col gap-1">
                <span className="p1-semiBold text-greyscaleGON-700 capitalize">
                  {item?.username}
                </span>
                <span className="p3-regular text-greyscaleGON-700 capitalize">
                  {item?.profession}
                </span>
              </div>
            </div>
          </div>
        )
      }
    />
  );
}

export function TestimoniColumn({ datas = [] }) {
  return datas?.map((item, index) => {
    return (
      <div
        key={index}
        className="flex flex-col gap-2 xs:gap-4 w-auto min-h-[200px] max-w-sm min-w-[265px] lg:min-w-[165px] p-4 xs:p-6  rounded bg-shadesGON-50 shadow-mediumGON"
      >
        <div className="h-full  w-full overflow-hidden overflow-y-scroll">
          <span className="p2-regular xs:p1-regular text-greyscaleGON-400 ">
            “{item?.message}”
          </span>
        </div>
        <Divider />
        <div className="flex gap-4 items-center">
          <div className="w-10 h-10">
            <Image
              unoptimized
              alt={`as_${index}`}
              src={item?.link_icon ?? AvatarImage}
              width={140}
              height={140}
              className="w-auto h-auto bg-cover bg-center rounded-full"
              placeholder="blur"
              blurDataURL={AvatarImage?.blurDataURL}
            />
          </div>
          <div className="flex flex-col gap-1">
            <span className="p1-semiBold text-greyscaleGON-700 capitalize">
              {item?.username}
            </span>
            <span className="p2-regular text-greyscaleGON-400 capitalize">
              {item?.profession}
            </span>
          </div>
        </div>
      </div>
    );
  });
}
