"use client";

import usePagination from "@/functions/usePagination";
import { ChevronLeftSvg, ChevronRightSvg } from "@/public/svg";
import { useRef, useState } from "react";
import { twMerge } from "tailwind-merge";

let DOTS = "...";

export default function Pagination({
  variant = "outlined",
  onPageChange,
  totalCount,
  siblingCount = 1,
  currentPage,
  pageSize,
}) {
  const paginationRange = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    pageSize,
    dots: DOTS,
  });

  // If there are less than 2 times in pagination range we shall not render the component
  if (currentPage === 0 || paginationRange?.length < 2) {
    return null;
  }

  let lastPage = paginationRange?.[paginationRange?.length - 1];

  const isStyle = variant == "solid" ? "border" : "";

  function handleClick(mode) {
    window.scrollTo(0, 0);
    if (mode == "add" && currentPage !== lastPage)
      return onPageChange(currentPage + 1);
    if (mode == "min" && currentPage !== 1)
      return onPageChange(currentPage - 1);
  }

  return (
    <>
      <div className='flex gap-2'>
        <div
          onClick={() => handleClick("min")}
          disabled={currentPage === 1}
          className={`p-2 rounded-[4px] text-greyscaleGON-400 ${isStyle} border-greyscaleGON-200 hover:bg-greyscaleGON-300 focus:bg-primaryGON-400 focus:text-white focus:border-primaryGON-400 disabled:text-greyscaleGON-300 disabled:bg-transparent disabled:cursor-not-allowed`}>
          <ChevronLeftSvg className={`h-6 w-6`} />
        </div>
        {paginationRange?.map((item, index) => {
          let actives = item === currentPage;
          return (
            <p
              key={index}
              onClick={() => {
                window.scrollTo(0, 0);
                item !== DOTS && onPageChange(item);
              }}
              className={twMerge(
                `p1-regular p-2 min-w-[28px] text-center rounded cursor-pointer`,
                actives
                  ? "bg-primaryGON-400 border-primaryGON-400 text-white"
                  : " border-greyscaleGON-200 hover:bg-greyscaleGON-300  focus:border-greyscaleGON-400 text-greyscaleGON-400"
              )}>
              {item}
            </p>
          );
        })}
        <div
          onClick={() => currentPage !== lastPage && handleClick("add")}
          className={`p-2 rounded-[4px] text-greyscaleGON-400 ${isStyle} border-greyscaleGON-200  hover:bg-greyscaleGON-300 focus:bg-primaryGON-400 focus:text-white focus:border-primaryGON-400 disabled:text-greyscaleGON-300 disabled:bg-transparent disabled:cursor-not-allowed`}>
          <ChevronRightSvg className={`h-6 w-6`} />
        </div>
      </div>
    </>
  );
}

export function ScrollPagination({ datas, renderItem, className }) {
  const scrollContainerRef = useRef(null);
  const [isDragging, setIsDragging] = useState(false);
  const [startPosition, setStartPosition] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);

  const handleMouseDown = (e) => {
    e.preventDefault(); // Prevent text selection/focus
    setIsDragging(true);
    setStartPosition(e.pageX - scrollContainerRef.current.offsetLeft);
    setScrollLeft(scrollContainerRef.current.scrollLeft);
    scrollContainerRef.current.classList.add("cursor-grabbing");
  };

  const handleMouseUp = () => {
    setIsDragging(false);
    scrollContainerRef.current.classList.remove("cursor-grabbing");
  };

  const handleMouseLeave = () => {
    setIsDragging(false);
    scrollContainerRef.current.classList.remove("cursor-grabbing");
  };

  const handleMouseMove = (e) => {
    if (!isDragging) return;
    const x = e.pageX - scrollContainerRef.current.offsetLeft;
    const walk = (x - startPosition) * 1.5;
    scrollContainerRef.current.scrollLeft = scrollLeft - walk;
  };

  return (
    datas?.length > 0 && (
      <div
        className={twMerge(
          "overflow-x-auto max-w-screen cursor-grab select-none",
          className
        )}
        ref={scrollContainerRef}
        onMouseDown={handleMouseDown}
        onMouseUp={handleMouseUp}
        onMouseLeave={handleMouseLeave}
        onMouseMove={handleMouseMove}>
        {datas?.map((item, index) => (
          <div key={index}>{renderItem({ item, index })}</div>
        ))}
      </div>
    )
  );
}
